import { useEffect } from 'react'

import { logger } from '@chat/services/logger'
import type { LoaderFunctionArgs, MetaFunction } from '@remix-run/node'
import {
  isRouteErrorResponse,
  useLoaderData,
  useRouteError,
} from '@remix-run/react'
import { setContext } from '@sentry/remix'

import { ErrorBoundary as ChatErrorBoundary } from '../components/error-boundary'
import { validatePublicKey } from '../helpers/auth'
import type { ERROR_CODE } from '../helpers/errors'
import { getSession } from '../services/session/session.server'

import { initAnonymous } from './init/anonymous'
import { initTrusted } from './init/trusted'

export const meta: MetaFunction = () => {
  return [{ title: 'Lorikeet AI chat' }]
}

export const loader = async (args: LoaderFunctionArgs) => {
  logger.log('GET /widget', {
    url: args.request.url,
  })

  const { request } = args
  const url = new URL(request.url)
  const session = await getSession(args)
  const publicKey = url.searchParams.get('public_key')
  const customerEmail = url.searchParams.get('customer_email')
  const customerName = url.searchParams.get('customer_name')
  const token = url.searchParams.get('token')
  const customisations = url.searchParams.get('customisations')
  const customerId = url.searchParams.get('anonymousId')
  const debug = url.searchParams.has('debug')

  if (!publicKey) {
    throw new Response('Public key must be provided', { status: 400 })
  }

  // Validate the API key prior to rendering the page
  const authenticatedInfo = await validatePublicKey(publicKey)
  const subscriberId = authenticatedInfo.subscriberId

  setContext('subscriber', {
    id: subscriberId,
    publicKey,
  })

  if (token) {
    // if there is a token this is a trusted session (we assume)
    return await initTrusted({
      publicKey,
      subscriberId,
      token,
      customisations,
      session,
    })
  }

  // if there is no customerId + no token this is an error state
  if (!customerId) {
    return {
      error: 'CUSTOMER_ID_MISSING' as ERROR_CODE,
      debug,
    }
  }

  // standard anonymous session
  try {
    return await initAnonymous({
      publicKey,
      subscriberId,
      customerEmail,
      customerName,
      customerId,
      customisations,
      debug,
      session,
    })
  } catch (error) {
    logger.error('Error initializing anonymous session', {
      error,
    })
    return {
      error: error as ERROR_CODE,
      debug,
    }
  }
}

export default function Widget() {
  const data = useLoaderData<typeof loader>()

  useEffect(() => {
    // post message to parent window
    if (data.error) {
      window.parent.postMessage(
        {
          type: 'error',
          error: data.error,
        },
        '*',
      )
    }
  }, [])

  return (
    <ChatErrorBoundary error={data.error} title="Something went wrong">
      Looks like your session is in a bad state. We recommend refreshing the
      page.
    </ChatErrorBoundary>
  )
}

/**
 * Reserverd for route error handling
 */
export const ErrorBoundary = () => {
  const error = useRouteError()
  const isRouteError = isRouteErrorResponse(error)

  useEffect(() => {
    window.parent.postMessage(
      {
        type: 'error',
        error: isRouteError ? 'UNKNOWN_PUBLIC_KEY' : 'FATAL_ERROR',
      },
      '*',
    )
  }, [])

  if (isRouteError) {
    return (
      <ChatErrorBoundary error="UNKNOWN_PUBLIC_KEY" title="Configuration error">
        We don't recognise this chat instance. Looks like a misconfiguration.
      </ChatErrorBoundary>
    )
  }

  return (
    <ChatErrorBoundary error="FATAL_ERROR" title="Something went wrong">
      We're not sure what went wrong, but the chat instance is in a bad state.
    </ChatErrorBoundary>
  )
}
